document.addEventListener('DOMContentLoaded', function() {

    /**
     * Languaje routing Logic
     */
    const supportedLangs = ['de', 'fr', 'it'];

    let currentPath = window.location.pathname;

    function detectUserLanguage() {
        const userLang = navigator.language || navigator.languages[0];
        const shortLang = userLang.slice(0, 2); 
    
        if (supportedLangs.includes(shortLang)) {
            return shortLang;
        } else {
            return 'de'; 
        }
    }

    if (!supportedLangs.some(l => currentPath.includes(`/${l}/`))) {
        const detectedLang = detectUserLanguage();
            
        window.location.href = `/${detectedLang}${currentPath}`;
        } else {
        if (currentPath.endsWith('index.html')) {

            let cleanPath = currentPath.replace('index.html', '');
            window.history.replaceState(null, null, cleanPath);
        }
    }

    /**
     * Download modal logic
     */

    const appstoreDownload = document.querySelector('#appstoreDownload');
    const gplayDownload = document.querySelector('#gplayDownload');

    const downloadModal = document.querySelector('#downloadModal');

    const closeModal = document.querySelector('#closeModal');

    appstoreDownload.addEventListener('click', function() {
        downloadModal.classList.toggle('hidden');
        }
    );
    gplayDownload.addEventListener('click', function() {
        downloadModal.classList.toggle('hidden');
        }
    );

    closeModal.addEventListener('click', function() {
        downloadModal.classList.toggle('hidden');
    }
    );

    /**
     * Languaje selector logic
     */

    const languageSwitcher = document.querySelector('#language-switcher');
    const langMenu = document.querySelector('#langSelectorMenu');

    const currentLangSpan = document.querySelector('#currentLang');

    //Change the value of the current language span to the current language
    currentLangSpan.textContent = currentPath.slice(1, 3).toUpperCase();

    languageSwitcher.addEventListener('click', function() {
        langMenu.classList.toggle('menu-block');
    });

    /**
     * Current year logic
     */

    const currentYear = document.querySelector('#currentYear');

    const current = new Date().getFullYear();

    currentYear.textContent = `© Zur Rose ${current}`;

    /**
     * Collapsible footer logic
     */
    const collapsibleHeaders = document.querySelectorAll('.footer__middle .collapsible');

        collapsibleHeaders.forEach(function(header) {
            header.addEventListener('click', function() {
                const parentCol = header.parentElement;

                const content = parentCol.querySelector('.content');

                const arrow = header.querySelector('.arrow');
                
                content.classList.toggle('active');
                arrow.classList.toggle('rotated');
            });
        });
    });